import React from "react";
import { Card, CardContent, Button, Typography, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
const IntegrationInfoCard = ({
  color,
  icon,
  title,
  btnText,
  onClick,
  btnText2,
  onClick2,
}) => {
  return (
    <Card
      sx={{
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#37cf50",
          borderRadius: "10px",
          width: "80px",
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          marginRight: "10px",
        }}
      >
        {/* <Typography variant="h6">{icon}</Typography>
         */}
        <Icon fontSize="medium" color="red">
          {icon}
        </Icon>
      </Box>
      <CardContent sx={{ flex: 1 }}>
        <Typography
          variant="h6"
          fontWeight="medium"
          mb={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {title}
        </Typography>
      </CardContent>
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          sx={{ borderRadius: "10px", fontWeight: "bold" }}
          onClick={onClick}
        >
          {btnText}
        </Button>
        {btnText2 && (
          <IconButton aria-label="setting" onClick={onClick2}>
            <SettingsIcon />
          </IconButton>
        )}
      </Stack>
    </Card>
  );
};

export default IntegrationInfoCard;
