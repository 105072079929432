export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SELECTED_USERS_DATA = "SELECTED_USERS_DATA";
///////////////ERROR//////////
export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_AUTH = "CLEAR_AUTH";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_ERRORS = "GET_ERRORS";
/////////////////USERS/////////////////////
export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";
export const ALL_USERS_DATA = "ALL_USERS_DATA";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const DELETE_USERS = "DELETE_USERS";
export const DELETE_USERS_FAIL = "DELETE_USERS_FAIL";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USERS_FAIL = "UPDATE_USERS_FAIL";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";
export const CLEAR_USER = "CLEAR_USER";

/////////////////////ROLES///////////////////////////////
export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";
export const ALL_ROLES_DATA = "ALL_ROLES_DATA";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";
export const EDIT_ROLE = "EDIT_ROLE";
export const EDIT_ROLE_FAIL = "EDIT_ROLE_FAIL";
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

////////////////////LOCATIONS////////////////////

export const ALL_LOCATIONS_DATA = "ALL_LOCATIONS_DATA";
export const GET_LOCATIONS_FAIL = "GET_LOCATIONS_FAIL";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_FAIL = "CREATE_LOCATION_FAIL";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SPLIT = "DELETE_LOCATION_SPLIT";
export const DELETE_LOCATION_SPLIT_FAIL = "DELETE_LOCATION_SPLIT_FAIL";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const ASSIGN_SPLITS = "ASSIGN_SPLITS";
export const ASSIGN_SPLITS_FAIL = "ASSIGN_SPLITS_FAIL";
export const EDIT_LOCATION_FAIL = "EDIT_LOCATION_FAIL";
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL";
export const CLEAR_LOCATIONS = "CLEAR_LOCATIONS";

//////////////////// REPORT //////////////////////////////
export const CREATE_REPORT = "CREATE_REPORT";
export const CREATE_REPORT_FAIL = "CREATE_REPORT_FAIL";
export const ALL_REPORT_DATA = "ALL_REPORT_DATA";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";
export const GENERATE_NOW = "GENERATE_NOW";
export const GENERATE_NOW_FAIL = "GENERATE_NOW_FAIL";
export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_REPORT_FAIL = "DELETE_REPORT_FAIL";

/////////////////// ENTITY  ////////////////////
export const UPDATE_ENTITY = "UPDATE_ENTITY";
export const UPDATE_ENTITY_FAIL = "UPDATE_ENTITY_FAIL";
export const CREATE_ENTITY = "CREATE_ENTITY";
export const CREATE_ENTITY_FAIL = "CREATE_ENTITY_FAIL";
export const ENTITIES_DATA = "ENTITIES_DATA";
export const ALL_ENTITYS_DATA = "ALL_ENTITYS_DATA";
export const GET_ENTITYS_FAIL = "GET_ENTITYS_FAIL";
export const DELETE_ENTITY = "DELETE_ENTITY";
export const DELETE_ENTITY_FAIL = "DELETE_ENTITY_FAIL";

/////////////////// ENTITY  ////////////////////
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL";
export const RECONCILIATION = "RECONCILIATION";
export const RECONCILIATION_FAIL = "RECONCILIATION_FAIL";

/////////////////// HISTORY  ////////////////////
export const HISTORY = "FILE_UPLOAD";
export const ALL_HISTORY_DATA = "ALL_HISTORY_DATA";
export const ALL_HISTORY_DATA_SUCCESS = "ALL_HISTORY_DATA_SUCCESS";
export const GET_HISTORY_FAIL = "GET_HISTORY_FAIL";

//////////////// FORMULA /////////////////////

export const CREATE_FROMULA = "CREATE_FROMULA";
export const ASSIGN_FROMULA = "ASSIGN_FROMULA";
export const ASSIGN_FROMULA_FAIL = "ASSIGN_FROMULA_FAIL";
export const UPDATE_FROMULA = "UPDATE_FROMULA";
export const UPDATE_FROMULA_FAIL = "UPDATE_FROMULA_FAIL";
export const DELETE_FORMULA = "DELETE_FORMULA";
export const DELETE_FORMULA_LOCATION = "DELETE_FORMULA_LOCATION";
export const DELETE_FORMULA_LOCATION_FAIL = "DELETE_FORMULA_LOCATION_FAIL";
export const DELETE_FORMULA_FAIL = "DELETE_FORMULA_FAIL";
export const CREATE_FROMULA_FAIL = "CREATE_FROMULA_FAIL";
export const FORMULA_DATA = "FORMULA_DATA";
export const GET_FORMULA_FAIL = "GET_FORMULA_FAIL";
