import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import history from "../../history";
import Users from "../../pages/Users";
import Roles from "../../pages/Roles";
import Locations from "../../pages/Locations";
import Entity from "../../pages/Entity";
import Report from "../../pages/Report";
import Reconciliation from "../../pages/Reconciliation";
import History from "../../pages/History";
import ProfilePage from "../../pages/Profile";
import Login from "../../pages/Login";
import Snackbar from "../Snackbar";
import Docs from "../../pages/Docs";
import Formula from "../../pages/formula";
import { loadUser } from "../../actions/authActions";
import { Integration } from "../../pages/Integration";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // access isAuthenticated from state auth
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, []);
  const isAuthenticated = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.userData);

  const permissions = get(userData, "role.modules", []);
  return (
    <Route
      {...rest}
      render={(props) =>
        //if isAuthenticated is true, show component
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          // else redirect to the login page
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export const App = () => {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Snackbar />
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={Login} />
            <PrivateRoute path="/settings" exact component={Roles} />
            <PrivateRoute path="/users" exact component={Users} />
            <PrivateRoute path="/locations" exact component={Locations} />
            <PrivateRoute path="/entity" exact component={Entity} />
            <PrivateRoute path="/formula" exact component={Formula} />
            <PrivateRoute path="/reports" exact component={Report} />
            <PrivateRoute path="/integrations" exact component={Integration} />
            <PrivateRoute
              path="/reconciliation"
              exact
              component={Reconciliation}
            />
            <PrivateRoute path="/history" exact component={History} />
            <PrivateRoute path="/profile" exact component={ProfilePage} />
            <PrivateRoute path="/api-docs" exact component={Docs} />
          </Switch>
        </Router>
      </Box>
    </>
  );
};

export default App;
