import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  LOAD_USER,
  LOAD_USER_FAIL,
  REGISTER_SUCCESS,
  CLEAR_AUTH,
  SELECTED_USERS_DATA,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  currentUser: null,
  userData: [],
  registerRes: null,
};
// reducer to handle auth Actions
export default (state = initialState, action) => {
  // console.log('action --> ', action)
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        registerRes: action.payload,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        currentUser: {
          _id: action.payload._id,
          email: action.payload.email,
          role: action.payload.role,
        },
        isAuthenticated: true,
      };
    case SELECTED_USERS_DATA:
      return {
        ...state,
        loading: false,
        selectedUser: action.payload,
      };
    // case REGISTER_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        currentUser: null,
      };
    case LOAD_USER:
      return {
        ...state,
        userData: action.payload,
      };
    case LOAD_USER_FAIL:
    // case REGISTER_FAIL:
    case LOGIN_FAIL:
    case CLEAR_AUTH:
      return {
        ...state,
        registerRes: null,
      };

    default:
      return state;
  }
};
