import React, { useState, useEffect, useCallback } from "react";
import SideNav from "../../components/NavBar";
import brandLogo from "../../images/SettleIt.svg";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IntegrationInfoCard from "./IntegrationCard.js";
import APIModal from "./settings.js";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../actions/userAction.js";
import { EventEmitter } from "../../components/Common/EventEmitter";
import { updateAdminData, getAdminData } from "../../actions/authActions.js";
import CircularProgress from "@mui/material/CircularProgress";
const container = {
  margin: "20px",
  backgroundColor: "transparent",
  borderRadius: "25px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  padding: "20px",
  height: "70%",
};

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 12;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const Integration = () => {
  const { users } = useSelector((state) => state.user);
  const { selectedUser } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const admins = users?.filter((user) => user.role.title === "admin");
  const [openModal, setOpenModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(selectedUser || {});

  const [isLoading, setIsLoading] = useState(false);
  const [fetchingAdminData, setFetchingAdminData] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch, loading]);

  useEffect(() => {
    if (selectedAdmin && selectedAdmin._id) {
      setFetchingAdminData(true);

      dispatch(getAdminData({ userId: selectedAdmin._id }))
        .then(() => {
          setFetchingAdminData(false);
        })
        .catch((err) => {
          EventEmitter.dispatch("message", "Something went wrong");
          EventEmitter.dispatch("close");
        });
    }
  }, [selectedAdmin?._id, dispatch]);

  const handleChange = (event) => {
    const selectedId = event.target.value;
    if (!selectedId) {
      setSelectedAdmin(null);
    } else {
      const selectedUser = admins.find((user) => user._id === selectedId);
      setSelectedAdmin(selectedUser);
    }
  };

  const modalOpen = () => {
    setOpenModal(true);
  };

  const handleIntegrationToggle = useCallback(
    (type, rest) => {
      if (!selectedAdmin._id) {
        EventEmitter.dispatch("message", "Please select an admin first");
        EventEmitter.dispatch("close");
        return;
      }

      setIsLoading(true);

      dispatch(updateAdminData({ userId: selectedAdmin._id, type, ...rest }))
        // .unwrap()
        .then((res) => {
          setIsLoading(false);
          EventEmitter.dispatch("message", "Integration updated");
          EventEmitter.dispatch("close");
          setSelectedAdmin({ ...selectedAdmin, ...rest });
        })
        .catch(() => setIsLoading(false));
    },
    [dispatch, selectedAdmin]
  );

  return (
    <>
      <section className="DashboardMainWrapper">
        <SideNav />
        <div className="DashboardWrapper">
          <div className="brandLogo">
            <img src={brandLogo} alt="Brand Logo" />
          </div>
          <div className="dashboardHeader">
            <h4>
              Integrations <span>/2</span>
            </h4>
          </div>
          <div style={container}>
            <Box sx={{ p: 3 }}>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mb: 3,
                  "& .MuiOutlinedInput-root": {
                    height: "38px", // Adjust the height of the Select input
                    borderRadius: "8px", // Rounded corners
                  },
                  "& .MuiInputLabel-root": {
                    top: "-5px", // Center the label vertically
                  },
                  "& .MuiSelect-select": {
                    padding: "16px", // Adjust padding inside the Select
                  },
                }}
              >
                <InputLabel>Admin</InputLabel>
                <Select
                  label="Select Admin"
                  value={
                    selectedAdmin && selectedAdmin._id ? selectedAdmin._id : ""
                  }
                  onChange={handleChange}
                  input={<OutlinedInput label="Admin" />}
                  MenuProps={MenuProps}
                  renderValue={(selectedId) => {
                    if (!selectedId) {
                      return "Select admin";
                    }
                    const selectedUser = admins.find(
                      (user) => user._id === selectedId
                    );
                    return selectedUser
                      ? selectedUser.fullName
                      : "Select admin";
                  }}
                >
                  <MenuItem value="" disabled>
                    Select admin
                  </MenuItem>
                  {admins?.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ p: 3, position: "relative" }}>
              {fetchingAdminData && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 10,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Grid container alignItems="center" spacing={4} py={2}>
                {[
                  {
                    icon: "V",
                    title: "Vendor",
                    isEnable: selectedAdmin.isVendorApiEnable || false,
                    onToggle: () =>
                      handleIntegrationToggle("UPDATE", {
                        isVendorApiEnable: !(
                          selectedAdmin.isVendorApiEnable || false
                        ),
                      }),
                  },
                  {
                    icon: "L",
                    title: "Live Api",
                    isEnable: selectedAdmin.isLiveApiEnable || false,
                    onToggle: () => {
                      handleIntegrationToggle("UPDATE", {
                        isLiveApiEnable: !(
                          selectedAdmin.isLiveApiEnable || false
                        ),
                      });
                    },
                    setting: true,
                  },

                  // More integrations...
                ].map((integration, index) => (
                  <Grid item md={4} sm={6} xs={12} key={index}>
                    <IntegrationInfoCard
                      color={"blue"}
                      icon={integration.icon}
                      title={integration.title}
                      btnText={integration.isEnable ? "Disable" : "Enable"}
                      onClick={integration.onToggle}
                      btnText2={integration.setting}
                      onClick2={modalOpen}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
          {openModal && (
            <APIModal open={openModal} onClose={() => setOpenModal(false)} />
          )}
        </div>
      </section>
    </>
  );
};
