import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Grid,
  Tabs,
  Tab,
  TextareaAutosize,
  LinearProgress,
} from "@mui/material";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  borderRadius: "30px",
  p: 4,
};

const methodStyle = {
  height: "30px",
  borderRadius: 0,
  "& fieldset": {
    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
  },
};

const urlStyle = {
  height: "30px",
  borderRadius: 0,
  "& fieldset": {
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
  },
};

const buttonStyle = (isActive) => ({
  backgroundColor: isActive ? "#126EF7" : "#e0e0e0",
  color: isActive ? "#fff" : "#000",
  marginRight: "10px",
  "&:hover": {
    backgroundColor: isActive ? "#0d4ca1" : "#d0d0d0",
  },
});

const APIModal = ({ open, onClose }) => {
  const [locationsApi, setLocationsApi] = useState({
    method: "GET",
    url: "",
    headers: "",
    params: "",
    bodyData: "",
    response: null,
    dataLocation: "",
    keysMapping: {
      id: "",
      workSpace: "",
      locationsAddress: "",
    },
  });

  const [transactionsApi, setTransactionsApi] = useState({
    method: "GET",
    url: "",
    headers: "",
    params: "",
    bodyData: "",
    response: null,
    dataLocation: "",
    keysMapping: {
      transactionId: "",
      status: "",
      amount: "",
      paymentGatway: "",
      transactionDate: "",
    },
  });

  const [apiResponse, setApiResponse] = useState(null);
  const [activeApi, setActiveApi] = useState("locations");
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cronSchedule, setCronSchedule] = useState("");
  const [selectedKeys, setSelectedKeys] = useState({
    key1: "",
    key2: "",
    key3: "",
    key4: "",
    key5: "",
  });

  // const [lkeys, setLkeys] = useState({
  //   key1: "",
  //   key2: "",
  //   key3: "",
  // });
  // const [tkeys, setTkeys] = useState({
  //   key1: "",
  //   key2: "",
  //   key3: "",
  //   key4: "",
  //   key5: "",
  //   // key6: "",
  // });
  useEffect(() => {
    if (open) {
      setLocationsApi({
        method: "GET",
        url: "",
        headers: "",
        params: "",
        bodyData: "",
        response: null,
        dataLocation: "",
      });
      setTransactionsApi({
        method: "GET",
        url: "",
        headers: "",
        params: "",
        bodyData: "",
        response: null,
        dataLocation: "",
      });
      setActiveApi("locations");
      setTabIndex(0);
      setLoading(false);
      setCronSchedule("");
      setSelectedKeys({
        key1: "",
        key2: "",
        key3: "",
        key4: "",
        key5: "",
      });
    }
  }, [open]);

  const getNestedData = (obj, path) => {
    if (!path) return obj;
    return path.split(".").reduce((o, key) => (o ? o[key] : null), obj);
  };

  const handleApiRequest = async () => {
    const apiData = activeApi === "locations" ? locationsApi : transactionsApi;

    setLoading(true);

    try {
      let res;
      const config = {
        headers: JSON.parse(apiData.headers || "{}"),
      };

      if (apiData.method === "GET") {
        res = await axios.get(apiData.url, {
          ...config,
          params: JSON.parse(apiData.params || "{}"),
        });
      } else if (apiData.method === "POST") {
        res = await axios.post(
          apiData.url,
          JSON.parse(apiData.bodyData || "{}"),
          config
        );
      }

      const dataLocation = apiData.dataLocation || "";
      const responseData = JSON.stringify(
        getNestedData(res.data, dataLocation),
        null,
        2
      );

      if (activeApi === "locations") {
        setLocationsApi({ ...apiData, response: responseData });
      } else {
        setTransactionsApi({ ...apiData, response: responseData });
      }
      setApiResponse(responseData);
    } catch (error) {
      const errorMessage = `Error: ${error.message}`;
      if (activeApi === "locations") {
        setLocationsApi((prev) => ({ ...prev, response: errorMessage }));
        setApiResponse(errorMessage);
      } else {
        setTransactionsApi((prev) => ({ ...prev, response: errorMessage }));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleApiSwitch = (api) => {
    setActiveApi(api);
    setTabIndex(0);
  };

  const renderKeyMapping = () => {
    const numSelectors = activeApi === "locations" ? 3 : 4;
    const locationkeysArray = ["id", "workSpace", "locationAddress"];
    const transactionKeysArray = [
      "transactionId",
      "status",
      "amount",
      "paymentGatway",
    ];
    const keyMappingFields = [];
    // Object.keys(locationsApi.keysMapping).map((el, i) => (
    //   <Grid container spacing={2} key={`key-mapping-${i}`} sx={{ mt: 1 }}>
    //     <Grid item xs={6}>
    //       <TextField
    //         select
    //         fullWidth
    //         label={`Key ${i + 1}`}
    //         val={
    //           activeApi === "locations" ? lkeys[`key${i}`] : tkeys[`key${i}`]
    //         }
    //         value={
    //           activeApi === "locations" ? lkeys[`key${i}`] : tkeys[`key${i}`]
    //         }
    //         onChange={(e) =>
    //           activeApi === "locations"
    //             ? setLkeys((prev) => ({
    //                 ...prev,
    //                 [`key${i}`]: e.target.value,
    //               }))
    //             : setTkeys((prev) => ({
    //                 ...prev,
    //                 [`key${i}`]: e.target.value,
    //               }))
    //         }
    //         InputProps={{
    //           style: { height: 40, width: "86%" },
    //         }}
    //       >
    //         {activeApi === "locations"
    //           ? Object.keys(locationsApi.keysMapping).map((key) => (
    //               <MenuItem key={key} value={key}>
    //                 {key}
    //               </MenuItem>
    //             ))
    //           : Object.keys(transactionsApi.keysMapping).map((key) => (
    //               <MenuItem key={key} value={key}>
    //                 {key}
    //               </MenuItem>
    //             ))}
    //       </TextField>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <TextField
    //         fullWidth
    //         label="How key is present in response"
    //         placeholder={"e.g., data.key"}
    //         onChange={(e) => {
    //           if (activeApi === "locations") {
    //             if (lkeys[`key${i}`].includes("key")) return;
    //             setLocationsApi((prev) => ({
    //               ...prev,
    //               keysMapping: {
    //                 ...prev.keysMapping,
    //                 [`${lkeys[`key${i}`]}`]: e.target.value,
    //               },
    //             }));
    //           } else {
    //             if (tkeys[`key${i}`].includes("key")) return;
    //             setTransactionsApi((prev) => ({
    //               ...prev,
    //               keysMapping: {
    //                 ...prev.keysMapping,
    //                 [`${tkeys[`key${i}`]}`]: e.target.value,
    //               },
    //             }));
    //           }
    //         }}
    //         InputProps={{
    //           style: { height: 40, width: "86%" },
    //         }}
    //       />
    //     </Grid>
    //   </Grid>
    // ));

    for (let i = 1; i <= numSelectors; i++) {
      keyMappingFields.push(
        <Grid container spacing={2} key={`key-mapping-${i}`} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label={`Key ${i}`}
              value={selectedKeys[`key${i}`]}
              onChange={(e) =>
                setSelectedKeys((prev) => ({
                  ...prev,
                  [`key${i}`]: e.target.value,
                }))
              }
              InputProps={{
                style: { height: 40, width: "86%" },
              }}
            >
              {activeApi === "locations"
                ? locationkeysArray.map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))
                : transactionKeysArray.map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="How key is present in response"
              placeholder={`e.g., data.${selectedKeys[`key${i}`]}`}
              InputProps={{
                style: { height: 40, width: "86%" },
              }}
            />
          </Grid>
        </Grid>
      );
    }
    return keyMappingFields;
  };

  // console.log(renderKeyMapping());
  const handleSave = () => {
    const apiData = {
      locationsApi,
      transactionsApi,
    };

    // console.log(apiData);
    // dispatch(liveData(apiData))
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" gutterBottom>
          VALIDATE API
        </Typography>

        <Box mb={3}>
          <Button
            onClick={() => handleApiSwitch("locations")}
            sx={buttonStyle(activeApi === "locations")}
          >
            Locations API
          </Button>
          <Button
            onClick={() => handleApiSwitch("transactions")}
            sx={buttonStyle(activeApi === "transactions")}
          >
            Transactions API
          </Button>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField
              select
              label="Method"
              value={
                activeApi === "locations"
                  ? locationsApi.method
                  : transactionsApi.method
              }
              onChange={(e) => {
                if (activeApi === "locations") {
                  setLocationsApi((prev) => ({
                    ...prev,
                    method: e.target.value,
                  }));
                } else {
                  setTransactionsApi((prev) => ({
                    ...prev,
                    method: e.target.value,
                  }));
                }
              }}
              fullWidth
              sx={methodStyle}
              InputProps={{
                style: { height: 40 },
              }}
            >
              <MenuItem value="GET">GET</MenuItem>
              <MenuItem value="POST">POST</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              value={
                activeApi === "locations"
                  ? locationsApi.url
                  : transactionsApi.url
              }
              onChange={(e) => {
                if (activeApi === "locations") {
                  setLocationsApi((prev) => ({ ...prev, url: e.target.value }));
                } else {
                  setTransactionsApi((prev) => ({
                    ...prev,
                    url: e.target.value,
                  }));
                }
              }}
              sx={urlStyle}
              InputProps={{
                style: { height: 40 },
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="contained"
              onClick={handleApiRequest}
              sx={{
                height: "40px",
                width: "60%",
                borderRadius: "10px",
                background: "#126EF7",
              }}
            >
              Send
            </Button>
          </Grid>

          {/* Data Location Key and Cron Schedule Row */}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Data Location Key"
                value={
                  activeApi === "locations"
                    ? locationsApi.dataLocation
                    : transactionsApi.dataLocation
                }
                onChange={(e) => {
                  if (activeApi === "locations") {
                    setLocationsApi((prev) => ({
                      ...prev,
                      dataLocation: e.target.value,
                    }));
                  } else {
                    setTransactionsApi((prev) => ({
                      ...prev,
                      dataLocation: e.target.value,
                    }));
                  }
                }}
                placeholder="e.g., grant.locations"
                InputProps={{
                  style: { height: 40 },
                }}
              />
            </Grid>

            {activeApi === "transactions" && (
              <Grid item xs={6}>
                <TextField
                  select
                  fullWidth
                  label="Cron Schedule"
                  value={cronSchedule}
                  onChange={(e) => setCronSchedule(e.target.value)}
                  InputProps={{
                    style: { height: 40, width: "86%" },
                  }}
                >
                  <MenuItem value="4">Every 4 hours</MenuItem>
                  <MenuItem value="6">Every 6 hours</MenuItem>
                  <MenuItem value="12">Every 12 hours</MenuItem>
                  <MenuItem value="24">Every 24 hours</MenuItem>
                  <MenuItem value="48">Every 48 hours</MenuItem>
                </TextField>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mt: 1 }}>
          <Tab label="Params" />
          <Tab label="Headers" />
          <Tab
            label="Body"
            disabled={
              locationsApi.method === "GET" && activeApi === "locations"
                ? true
                : false
            }
          />
          <Tab label="Key Maping" />
        </Tabs>

        {tabIndex === 0 && (
          <TextareaAutosize
            minRows={6}
            value={
              activeApi === "locations"
                ? locationsApi.params
                : transactionsApi.params
            }
            onChange={(e) => {
              if (activeApi === "locations") {
                setLocationsApi((prev) => ({
                  ...prev,
                  params: e.target.value,
                }));
              } else {
                setTransactionsApi((prev) => ({
                  ...prev,
                  params: e.target.value,
                }));
              }
            }}
            style={{ width: "100%", marginTop: "5px" }}
            placeholder='{"param1": "value1"}'
          />
        )}
        {tabIndex === 1 && (
          <TextareaAutosize
            minRows={6}
            value={
              activeApi === "locations"
                ? locationsApi.headers
                : transactionsApi.headers
            }
            onChange={(e) => {
              if (activeApi === "locations") {
                setLocationsApi((prev) => ({
                  ...prev,
                  headers: e.target.value,
                }));
              } else {
                setTransactionsApi((prev) => ({
                  ...prev,
                  headers: e.target.value,
                }));
              }
            }}
            style={{ width: "100%", marginTop: "5px" }}
            placeholder='{"header1": "value1"}'
          />
        )}
        {tabIndex === 2 && (
          <TextareaAutosize
            minRows={6}
            value={
              activeApi === "locations"
                ? locationsApi.bodyData
                : transactionsApi.bodyData
            }
            onChange={(e) => {
              if (activeApi === "locations") {
                setLocationsApi((prev) => ({
                  ...prev,
                  bodyData: e.target.value,
                }));
              } else {
                setTransactionsApi((prev) => ({
                  ...prev,
                  bodyData: e.target.value,
                }));
              }
            }}
            style={{ width: "100%", marginTop: "5px" }}
            placeholder='{"bodyKey": "bodyValue"}'
          />
        )}
        {tabIndex === 3 && (
          <>
            <Typography sx={{ mt: 2 }}>
              Map API response keys to fields for {activeApi}:
            </Typography>
            {renderKeyMapping()}
          </>
        )}

        {loading && (
          <Box mt={2}>
            <LinearProgress />
          </Box>
        )}

        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          Response:
        </Typography>
        <Box
          sx={{
            mt: 2,
            height: 150,
            overflowY: "scroll",
            p: 2,
            borderRadius: 2,
            border: "1px solid #ccc",
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "520px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
          }}
        >
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {activeApi === "locations"
              ? locationsApi.response || ""
              : transactionsApi.response || ""}
          </pre>
        </Box>

        <Box
          mt={2}
          // sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default APIModal;
